/* Fonts  */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

.brand-smart-pay-text {
    background: linear-gradient(to right, #297e29, #52bb44, #2ea8d8);
    background-size: contain;
    background-repeat: repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-style: normal;
    font-size: 4rem;
}

.login-kiosk {
    height: 100vh;
    margin: 0;
}

.pulse:active {
    transform: scale(0.95);
}

.img-modal-product {
    width: 560px;
    height: 565px;
    object-fit: cover;
    margin: 0rem 0;
}

@media (max-width:1080px) {

    .login-kiosk-img {
        height: 200px;
        width: 200px;
    }

    .login-kiosk-img-modal {
        height: 100px;
        width: 100px;
    }

    .login-kiosk-header {
        margin: 5px 0px;

    }

    .login-kiosk-header>h2 {
        font-family: "Titillium Web", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 4rem;
        margin: 5px 0px;
        color: #000000;
    }

    .login-kiosk-header>p {
        font-size: 2.1rem;
        margin: 40px 0px 0px 2px;
        color: #6d6d6d;
        font-weight: 800;
    }

    .login-kiosk-qr {
        width: 180px;
        height: 300px;
        object-fit: cover;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    .login-kiosk-key {
        width: 30%;
        margin: 30px 0px;
        object-fit: cover;
    }

    .login-kiosk-content>h3 {
        font-size: 2.9rem;
        /* margin: 20px 0px 0px 2px; */
        font-weight: 800;
    }

    .login-kiosk-content>p {
        font-size: 2.1rem;
        margin: 4px 0px;
        color: #071437;

    }

    .login-kiosk-content-number {
        top: -11px;
        left: 4%;
        font-size: 7.5rem;
        font-weight: 800;
        background: linear-gradient(to right, #297e29, #52bb44, #2ea8d8);
        background-size: contain;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .login-kiosk-content-text {
        font-size: 2.5rem;
        font-weight: 800;
    }

    .login-kiosk-text-alt {
        font-size: 2.5rem;
        font-weight: 700;
        margin: 5px 0px;
    }

    /* Modal Login Pin  */

    .border-pin {
        filter: drop-shadow(0 0 0.95rem rgb(255, 255, 255));
        -webkit-filter: drop-shadow(0 0 0.95rem rgb(255, 255, 255));
    }

    .keyboard-title-modal {
        font-family: "Titillium Web", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 4rem;
        margin: 5px 0px;
        color: #000000;
    }

    .keyboard-title-modal-brand {
        font-family: "Titillium Web", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 4rem;
        margin: 5px 0px;
        ;

        background: linear-gradient(to right, #297e29, #52bb44, #2ea8d8);
        background-size: contain;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .keyboard-main-pin {
        background: #fff;
        /* background: linear-gradient(0deg, #fff 89%, hsl(114, 100%, 89%)); */
        /* background: -webkit-linear-gradient(300deg,#fff 89%, #3aaa35,#329e74,#2686be);    
             
      */
    }

    .keyboard-pad>.key-num-pin,
    .delete {
        background-color: #e0e9fb;
        border: 2px solid #0e0594;
        border-radius: 20px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
        cursor: pointer;
        font-weight: 800;
        text-align: center;
        font-size: 50px;
        padding: 60px 0px;
        width: calc(33.333% - 8px);
        margin: 10px 5px 5px 2px;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        inset: 4px -4px 0px #d4c69d;
        inset: -4px 4px 0px #beb28d;
        border-radius: 30px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }


    .key-num-pin:active {
        box-shadow: #0e0594 2px 2px 0 0;
        transform: translate(10px, 10px);
        -webkit-transform: translate(10px, 10px);
        -moz-transform: translate(10px, 10px);
        -ms-transform: translate(10px, 10px);
        -o-transform: translate(10px, 10px);
    }

    @media (min-width: 768px) {
        .key-num-pin {
            min-width: 120px;
            padding: 0 25px;
        }
    }

    .keyboard-pad>.keyboardKey {
        color: #000;
        font-weight: 800;
        text-align: center;
        font-size: 2rem;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        padding: 45px 0px;
        width: calc(12.333% - 8px);
        margin: 0 4px 8px;
        cursor: pointer;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        border: 2px solid rgb(226, 226, 226);
        /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
        inset: 4px -4px 0px #d4c69d;
        inset: -4px 4px 0px #beb28d;
    }

    .keyboard-pad>.delete {
        color: #000;
        font-weight: 800;
        text-align: center;
        font-size: 2rem;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        padding: 45px 0px;
        width: calc(20.333% - 8px);
        margin: 0 4px 8px;
        cursor: pointer;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        border: 2px solid rgb(226, 226, 226);
        /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
        inset: 4px -4px 0px #d4c69d;
        inset: -4px 4px 0px #beb28d;
    }


    .num:active {
        box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
        inset: 3px -3px 0px #d4c69d;
        inset: -3px 3px 0px #beb28d;
        transform: translate(3px, 3px);
        -webkit-transform: translate(3px, 3px);
        -moz-transform: translate(3px, 3px);
        -ms-transform: translate(3px, 3px);
        -o-transform: translate(3px, 3px);
        transition: 0.2s ease;
        -webkit-transition: 0.2s ease;
        -moz-transition: 0.2s ease;
        -ms-transition: 0.2s ease;
        -o-transition: 0.2s ease;
    }

    .delete:active {
        box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
        inset: 3px -3px 0px #d49d9d;
        inset: -3px 3px 0px #be938d;
        transform: scale(-1px, -1px);
        -webkit-transform: scale(-1px, -1px);
        -moz-transform: scale(-1px, -1px);
        -ms-transform: scale(-1px, -1px);
        -o-transform: scale(-1px, -1px);
        transition: 0.2s ease;
        -webkit-transition: 0.2s ease;
        -moz-transition: 0.2s ease;
        -ms-transition: 0.2s ease;
        -o-transition: 0.2s ease;
    }

    .keypadPrimary-input_1 {
        color: #0025B8;
        width: 100%;
        margin: 40px 0;
        padding: 15px 0;
        font-size: 70px;
        font-weight: 700;
        border: 2px solid rgb(226, 226, 226);
        letter-spacing: 25px;
    }

    .btn-vending-product {
        font-size: 3rem;
        font-weight: 700;
        color: white;
        background-color: #0025B8;
        border: 1px solid #ccc;
        padding: 30px 70px;
    }


    .keypadPrimary-input_1 {
        color: #0025B8;
        width: 100%;
        margin: 30px 0;
        padding: 20px 0;
        font-size: 80px;
        font-weight: 700;
        letter-spacing: 25px;

        background-color: #ffffff;
        border: 2px solid #0025B8;
        border-radius: 30px;
        box-shadow: #0025B8 4px 4px 0 0;

    }


    .btn-vending-product {
        font-size: 70px;
        font-weight: 700;
        padding: 40px 40px;
        background-color: #e0e9fb;
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
    }

    .btn-vending-product:active {
        box-shadow: #0e0594 2px 2px 0 0;
        transform: translate(2px, 2px);
    }

    .btn-vending-success {
        margin-top: 10px;
        font-size: 60px;
        background-color: #e6fbe0;
        border: 2px solid #00b51a;
        border-radius: 30px;
        box-shadow: #00b51a 4px 4px 0 0;
        color: #00b51a;

    }


    .img-modal-product {
        Width: 515PX;
        height: 515px;
        object-fit: cover;
        margin: 60px 0;
    }



    .text-product-pay {
        font-size: 60px;
    }

    .text-title-subtotals {
        font-size: 45px;
    }

    .text-title-totals {
        font-size: 50px;
    }

    .border-vending-primary {
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
    }

    .text-msg-venidng {
        font-size: 70px;
    }

    .text-msg-footer {
        font-size: 50px;
    }

    .img-pay-group {
        background-color: #0025B8;
        width: 100px;
        height: 100px;
    }

    .btn-pay-meth {
        background-color: #0025B8;
        border: 1px solid #d1d1d1;
        color: white;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .btn-pay-meth:hover {
        border: 1px solid yellow;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .keypadPrimary-input_1 {
        color: #0025B8;
        width: 100%;
        margin: 30px 0;
        padding: 15px 0;
        font-size: 30px;
        font-weight: 700;
        border: 2px solid rgb(226, 226, 226);
        letter-spacing: 25px;


        background-color: #ffffff;
        border: 2px solid #0025B8;
        border-radius: 30px;
        box-shadow: #0025B8 4px 4px 0 0;
    }


    .btn-vending-product {
        font-size: 35px;
        font-weight: 700;
        padding: 10px 20px;
        background-color: #e0e9fb;
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
    }

    .btn-vending-product:active {
        box-shadow: #0e0594 2px 2px 0 0;
        transform: translate(2px, 2px);
    }

    .btn-vending-success {
        margin-top: 10px;
        font-size: 25px;
        background-color: #e6fbe0;
        border: 2px solid #00b51a;
        border-radius: 30px;
        box-shadow: #00b51a 4px 4px 0 0;
        color: #00b51a;
    }

    /* Pays Methos  */

    .text-product-pay {
        font-size: 60px;
    }


    .text-title-subtotals {
        font-size: 50px;
    }

    .text-title-totals {
        font-size: 60px;
    }

    .btn-menu-vending {
        font-size: 4.5rem;
        font-weight: 700;
        background-color: #eef8ff;
        padding: 12px 15px;
        border: 2px solid #0d6efd;
        border-radius: 30px;
        box-shadow: #0d6efd 4px 4px 0 0;
        color: #0d6efd;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .btn-return-vending {
        font-size: 1.5rem;
        font-weight: 700;
        background-color: #ffffff;
        padding: 10px 15px;
        border: 2px solid gray;
        border-radius: 30px;
        box-shadow: gray 4px 4px 0 0;
        color: gray;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .border-vending-secondary {
        border: 2px solid #e2e2e2;
        border-radius: 30px;
        box-shadow: #e2e2e2 4px 4px 0 0;
        color: #e2e2e2;
    }

    .border-vending-secondary-2 {
        border: 0px solidrgb(255, 255, 255)2;

    }

    .border-vending-info {
        background-color: #eef8ff;
        padding: 10px 15px;
        border: 2px solid #0d6dfd5e;
        border-radius: 30px;
        box-shadow: #0d6dfd5e 4px 4px 0 0;
        color: #0d6dfd5e;
    }

    .border-vending-primary {
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
    }

    .border-vending-success {
        border: 2px solid #00b51a;
        border-radius: 30px;
        box-shadow: #00b51a 4px 4px 0 0;
        color: #00b51a;
    }

    .border-vending-warning {
        border: 2px solid #ffbb00;
        border-radius: 30px;
        box-shadow: #ffbb00 4px 4px 0 0;
        color: #ffbb00;
    }

    .border-vending-danger {
        border: 2px solid #d50e0ef0;
        border-radius: 30px;
        box-shadow: #d50e0ef0 4px 4px 0 0;
        color: #d50e0ef0;
    }

    .border-vending-black {
        border: 2px solid #000;
        border-radius: 30px;
        box-shadow: #000 4px 4px 0 0;
        color: #000;
    }

    /* Modal Abastecimiento */
    .vendindg-title {
        margin: 10px 0;
    }

    /* Msg  */

    .img-dispensed {
        width: 100%;
    }

    .text-msg-venidng {
        font-size: 80px;
    }


    .text-msg-footer {
        font-size: 20px;
    }

    .img-ans {
        width: 200px;
        height: 200px;
    }

    .img-pay-group {
        background-color: #0d6efd;
        width: 300px;
        height: 300px;

    }


    .text-subtitle-box {
        font-size: 30px;
    }

    .btn-pay-meth {
        background-color: #0025B8;
        border: 1px solid #d1d1d1;
        color: white;

        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .btn-pay-meth:hover {
        border: 1px solid yellow;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;


    }


    .img-pay-meth-modal {
        width: 100%;
        height: 500px;
    }


    /* Stock  */

    .bar-buttons {
        position: absolute;
        bottom: 0;
        z-index: 1999;
    }

    .bar-returns {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1999;
    }

    .flex-container {
        color: #000000;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        background-color: #ffffff;
        height: 100%;
        gap: 1px;

    }

    .flex-container>div {
        border-radius: 5px;
        padding: 2px 4px;
    }


    .container-section {
        /* flex:3 1 auto; */
        order: 0;
        flex-grow: 1;
        overflow: auto;
    }

    .pb-items {
        padding-bottom: 40px;
    }

    .name-stock-item {
        font-size: 25px;

    }

    .empty-stock {
        margin: 10px 0;
        padding: 70px 10px;
        width: 90%;
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
        height: 500px;
    }

    .empty-stock>i {
        font-size: 5rem;
    }

    .empty-stock>p {
        font-weight: 700;
        font-size: 1rem;
    }

    .img-modal-product-stock-2 {
        Width: 215PX;
        height: 215px;
        object-fit: cover;
        margin: 0rem 0;
    }


    .number-counter {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number-counter p {
        font-size: 60px;
        font-weight: 700;
        margin: 0 3rem;
    }

    .number-counter button {
        font-weight: 800;
        font-size: 28rem;
        /* width: 5rem;
        height: 5rem; */
        border-radius: 50%;
        border: none;
        background-color: var(--primary-vending);
        color: #fff;
        cursor: pointer;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }


    .plus,
    .minus {
        position: relative;
    }

    .plus:before,
    .plus:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2rem;
        height: 4px;
        background-color: white;
    }

    .plus:before {
        transform: translate(-50%, -50%);
    }

    .plus:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }



    .minus:before,
    .minus:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2rem;
        height: 4px;
        background-color: var(--blanco);
    }

    .minus:before {
        transform: translate(-50%, -50%);
    }

    .minus:after {
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
    }

    .img-gif {
        width: 400px;
        height: 400px;
    }

    .text-msg-footer-2 {
        font-size: 65px;

    }

    .img-step {
        width: 100%;
        height: 400px;
    }

    
    .yappy-img {
        width: 200px;
        height: 200px;
    }
}


@media (max-width:480px) {

    .login-kiosk-img {
        height: 90px;
        width: 90px;
    }

    .login-kiosk-img-modal {
        display: none;
    }

    .login-kiosk-header {
        margin: 2px 0px;
    }

    .login-kiosk-header>h2 {
        font-family: "Titillium Web", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
        margin: 5px 0px;
        color: #000000;
    }

    .brand-smart-pay-text {
        background: linear-gradient(to right, #297e29, #52bb44, #2ea8d8);
        background-size: contain;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
    }

    .login-kiosk-header>p {
        font-size: 20px;
        margin: 40px 0px 0px 2px;
        color: #6d6d6d;
        font-weight: 800;
    }

    .login-kiosk-qr {
        width: 75px;
        height: 90px;
    }

    .login-kiosk-key {
        width: 60px;
        height: 60px;
        margin: 15px 0px;
        object-fit: cover;
    }

    .login-kiosk-content>h3 {
        font-size: 20px;
        /* margin: 20px 0px 0px 2px; */
        font-weight: 800;
    }

    .login-kiosk-content>p {
        font-size: 15px;
        margin: 4px 0px;
        color: #071437;

    }

    .login-kiosk-content-number {
        top: -11px;
        left: 4%;
        font-size: 7.5rem;
        font-weight: 800;
        background: linear-gradient(to right, #297e29, #52bb44, #2ea8d8);
        background-size: contain;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .login-kiosk-content-text {
        font-size: 25px;
        font-weight: 800;
    }

    .login-kiosk-text-alt {
        font-size: 20px;
        font-weight: 700;
        margin: 1px 0px;
    }

    /* Modal Login Pin  */

    .border-pin {
        filter: drop-shadow(0 0 0.95rem rgb(255, 255, 255));
        -webkit-filter: drop-shadow(0 0 0.95rem rgb(255, 255, 255));
    }

    .keyboard-title-modal {
        font-family: "Titillium Web", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        margin: 5px 0px;
        color: #000000;
    }

    .keyboard-title-modal-brand {
        font-family: "Titillium Web", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 25px;
        margin: 5px 0px;
        ;

        background: linear-gradient(to right, #297e29, #52bb44, #2ea8d8);
        background-size: contain;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .keyboard-steps-label {
        font-size: 15px;
        font-weight: 700;
    }

    .keyboard-main-pin {
        background: #fff;
        /* background: linear-gradient(0deg, #fff 89%, hsl(114, 100%, 89%)); */
        /* background: -webkit-linear-gradient(300deg,#fff 89%, #3aaa35,#329e74,#2686be);    
             
      */
    }

    .keyboard-pad>.key-num-pin,
    .delete {
        background-color: #e0e9fb;
        border: 2px solid #0e0594;
        border-radius: 20px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
        cursor: pointer;
        font-weight: 800;
        text-align: center;
        font-size: 25px;
        padding: 20px 0px;
        width: calc(33.333% - 8px);
        margin: 10px 5px 5px 2px;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        inset: 4px -4px 0px #d4c69d;
        inset: -4px 4px 0px #beb28d;
        border-radius: 30px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }


    .key-num-pin:active {
        box-shadow: #0e0594 2px 2px 0 0;
        transform: translate(2px, 2px);
    }

    @media (min-width: 768px) {
        .key-num-pin {
            min-width: 120px;
            padding: 0 25px;
        }
    }

    .keyboard-pad>.keyboardKey {
        color: #000;
        font-weight: 800;
        text-align: center;
        font-size: 2rem;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        padding: 45px 0px;
        width: calc(12.333% - 8px);
        margin: 0 4px 8px;
        cursor: pointer;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        border: 2px solid rgb(226, 226, 226);
        /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
        inset: 4px -4px 0px #d4c69d;
        inset: -4px 4px 0px #beb28d;
    }

    .keyboard-pad>.delete {
        color: #000;
        font-weight: 800;
        text-align: center;
        font-size: 2rem;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        padding: 45px 0px;
        width: calc(20.333% - 8px);
        margin: 0 4px 8px;
        cursor: pointer;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        border: 2px solid rgb(226, 226, 226);
        /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
        inset: 4px -4px 0px #d4c69d;
        inset: -4px 4px 0px #beb28d;
    }


    .num:active {
        box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
        inset: 3px -3px 0px #d4c69d;
        inset: -3px 3px 0px #beb28d;
        transform: translate(3px, 3px);
        -webkit-transform: translate(3px, 3px);
        -moz-transform: translate(3px, 3px);
        -ms-transform: translate(3px, 3px);
        -o-transform: translate(3px, 3px);
        transition: 0.2s ease;
        -webkit-transition: 0.2s ease;
        -moz-transition: 0.2s ease;
        -ms-transition: 0.2s ease;
        -o-transition: 0.2s ease;
    }

    .delete:active {
        box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
        inset: 3px -3px 0px #d49d9d;
        inset: -3px 3px 0px #be938d;
        transform: scale(-1px, -1px);
        -webkit-transform: scale(-1px, -1px);
        -moz-transform: scale(-1px, -1px);
        -ms-transform: scale(-1px, -1px);
        -o-transform: scale(-1px, -1px);
        transition: 0.2s ease;
        -webkit-transition: 0.2s ease;
        -moz-transition: 0.2s ease;
        -ms-transition: 0.2s ease;
        -o-transition: 0.2s ease;
    }


    .keypadPrimary-input_1 {
        color: #0025B8;
        width: 100%;
        margin: 30px 0;
        padding: 15px 0;
        font-size: 30px;
        font-weight: 700;
        border: 2px solid rgb(226, 226, 226);
        letter-spacing: 25px;


        background-color: #ffffff;
        border: 2px solid #0025B8;
        border-radius: 30px;
        box-shadow: #0025B8 4px 4px 0 0;
    }


    .btn-vending-product {
        font-size: 35px;
        font-weight: 700;
        padding: 10px 20px;
        background-color: #e0e9fb;
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
    }

    .btn-vending-product:active {
        box-shadow: #0e0594 2px 2px 0 0;
        transform: translate(2px, 2px);
    }

    .btn-vending-success {
        margin-top: 10px;
        font-size: 25px;
        background-color: #e6fbe0;
        border: 2px solid #00b51a;
        border-radius: 30px;
        box-shadow: #00b51a 4px 4px 0 0;
        color: #00b51a;
    }

    /* Pays Methos  */

    .text-product-pay {
        font-size: 30px;
    }

    .img-modal-product {
        Width: 315PX;
        height: 315px;
        object-fit: cover;

        margin: 2rem 0;
    }


    .text-title-subtotals {
        font-size: 20px;
    }

    .text-title-totals {
        font-size: 28px;
    }

    .btn-menu-vending {
        font-size: 1.5rem;
        font-weight: 700;
        background-color: #eef8ff;
        padding: 10px 15px;
        border: 2px solid #0d6efd;
        border-radius: 30px;
        box-shadow: #0d6efd 4px 4px 0 0;
        color: #0d6efd;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .btn-return-vending {
        font-size: 1.5rem;
        font-weight: 700;
        background-color: #ffffff;
        padding: 10px 15px;
        border: 2px solid gray;
        border-radius: 30px;
        box-shadow: gray 4px 4px 0 0;
        color: gray;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .border-vending-secondary {
        border: 2px solid #e2e2e2;
        border-radius: 30px;
        box-shadow: #e2e2e2 4px 4px 0 0;
        color: #e2e2e2;
    }

    .border-vending-secondary-2 {
        border: 2px solid #e2e2e2;
        border-radius: 30px;
        box-shadow: #e2e2e2 4px 4px 0 0;
        color: #e2e2e2;
    }

    .border-vending-info {
        background-color: #eef8ff;
        padding: 10px 15px;
        border: 2px solid #0d6dfd5e;
        border-radius: 30px;
        box-shadow: #0d6dfd5e 4px 4px 0 0;
        color: #0d6dfd5e;
    }

    .border-vending-primary {
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
    }

    .border-vending-success {
        border: 2px solid #00b51a;
        border-radius: 30px;
        box-shadow: #00b51a 4px 4px 0 0;
        color: #00b51a;
    }

    .border-vending-warning {
        border: 2px solid #ffbb00;
        border-radius: 30px;
        box-shadow: #ffbb00 4px 4px 0 0;
        color: #ffbb00;
    }

    .border-vending-danger {
        border: 2px solid #d50e0ef0;
        border-radius: 30px;
        box-shadow: #d50e0ef0 4px 4px 0 0;
        color: #d50e0ef0;
    }

    .border-vending-black {
        border: 2px solid #000;
        border-radius: 30px;
        box-shadow: #000 4px 4px 0 0;
        color: #000;
    }

    /* Modal Abastecimiento */
    .vendindg-title {
        margin: 10px 0;
    }

    /* Msg  */

    .img-dispensed {
        width: 100%;
    }

    .text-msg-venidng {
        font-size: 40px;
    }


    .text-msg-footer {
        font-size: 20px;
    }

    .img-ans {
        width: 200px;
        height: 200px;
    }

    .img-pay-group {
        background-color: #0d6efd;
        width: 130px;
        height: 155px;

    }

    .btn-pay-meth {
        background-color: #0025B8;
        border: 1px solid #d1d1d1;
        color: white;

        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .btn-pay-meth:hover {
        border: 1px solid yellow;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;


    }


    .img-pay-meth-modal {
        width: 100%;
        height: 500px;
    }


    /* Stock  */

    .bar-buttons {
        position: absolute;
        bottom: 0;
        z-index: 1999;
    }

    .bar-returns {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1999;
    }

    .flex-container {
        color: #000000;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        background-color: #ffffff;
        height: 100%;
        gap: 1px;

    }

    .flex-container>div {
        border-radius: 5px;
        padding: 2px 4px;
    }


    .container-section {
        /* flex:3 1 auto; */
        order: 0;
        flex-grow: 1;
        overflow: auto;
    }

    .pb-items {
        padding-bottom: 40px;
    }

    .name-stock-item {
        font-size: 25px;

    }

    .empty-stock {
        margin: 10px 0;
        padding: 70px 10px;
        width: 90%;
        border: 2px solid #0e0594;
        border-radius: 30px;
        box-shadow: #0e0594 4px 4px 0 0;
        color: #0e0594;
        height: 500px;
    }

    .empty-stock>i {
        font-size: 5rem;
    }

    .empty-stock>p {
        font-weight: 700;
        font-size: 1rem;
    }

    .img-modal-product-stock-2 {
        Width: 215PX;
        height: 215px;
        object-fit: cover;
        margin: 0rem 0;
    }


    .number-counter {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number-counter p {
        font-size: 60px;
        font-weight: 700;
        margin: 0 3rem;
    }

    .number-counter button {
        font-weight: 800;
        font-size: 28rem;
        /* width: 5rem;
        height: 5rem; */
        border-radius: 50%;
        border: none;
        background-color: var(--primary-vending);
        color: #fff;
        cursor: pointer;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }


    .plus,
    .minus {
        position: relative;
    }

    .plus:before,
    .plus:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2rem;
        height: 4px;
        background-color: white;
    }

    .plus:before {
        transform: translate(-50%, -50%);
    }

    .plus:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }



    .minus:before,
    .minus:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2rem;
        height: 4px;
        background-color: var(--blanco);
    }

    .minus:before {
        transform: translate(-50%, -50%);
    }

    .minus:after {
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
    }

    .img-gif {
        width: 200px;
        height: 200px;
    }

    .text-subtitle-box {
        font-size: 15px;
    }

    .text-msg-footer-2 {
        font-size: 25px;

    }

        .img-step {
        width: 100%;
        height: 200px;
    }

    .yappy-img {
        width: 100px;
        height: 78px;
    }

}