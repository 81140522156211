.logo-main-vending {
    width: 60px;
    height: 60px;
}


.vending-container-main {
    display: flex;
    height: 100vh;
    width: 100%;
}

.vending-flex-container {
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    background-color: #ffffff;
    height: 100%;
    width: 50%;
    gap: 1px;
}

.vending-content-items {
    /* flex: 3 1 auto; */
    order: 0;
    flex-grow: 1;
    overflow: auto;

}


.vending-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 37px;
    overflow: auto;
    flex-direction: row;
    position: absolute;
    z-index: 2;
}

.vending-row div {
    margin: 0 auto;
    /* width: 255px; */
    height: 207px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--negro);     */
}

.vending-img-product {
    width: 120px;
    height: 135px;
    object-fit: cover;
}




.vending-menu-products {
    padding-bottom: 5rem;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: transparent;

}

.title-product-menu {
    font-size: 17px;
    font-weight: 800;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 155px;
}

.title-price-menu {
    font-size: 25px;
}

/* 
.vending-content {
    flex: 1;
    overflow: auto;
    width: 50%;
} */


.img-modal-product-stock {
    width: 38rem;
    height: 38rem;
    object-fit: cover;
    margin: 7rem 0;
}

/*Modal Product IMG   */



.background-image {
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    position: absolute;
}

.foreground-image {
    z-index: 2;
    /* Ponerla adelante */
}



/* Modal Support  */

.help-img {

    width: 120px;
    height: 100px;
}

.text-title-help {
    font-size: 20px;
    color: #071437;
}

.num-key-pad {
    font-size: 35px;
}







@media (max-width: 1080px) {
    .vending-flex-container {
        color: #000000;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        gap: 1px;
        z-index: 1;
    }

    .title-product-menu {
        font-size: 25px;
        font-weight: 800;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 220px;
    }

    .vending-menu-products {
        padding-bottom: 5rem;
        margin-top: 2rem;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .vending-img-product {
        width: 220px;
        height: 235px;
        object-fit: cover;
    }

    .title-product-menu {
        font-size: 25px;
        font-weight: 800;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 220px;
    }

    .title-price-menu {
        font-size: 30px;
        color: #ffffff;
        background-color: #00b51a;
        font-weight: 800;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }


    /* Meth pays */
    .img-product-cart {
        width: 2rem;
        height: 2rem;
    }

    .img-pay-meth {
        width: 200px;
        height: 200px;
    }



    .img-pay-group:hover {
        background-color: #021561;
    }




    .modal-title-product {
        font-size: 50px;
        font-weight: 700;
    }


    .modal-price-product {
        font-size: 45px;
        font-weight: 800;
        color: #00b51a;
        margin: 0rem 0;
    }

    .modal-price-product-before {
        color: red;
        font-size: 4rem;
        font-weight: 600;
        margin: 4rem 0rem 0rem 0rem;

    }

    .btn-success-send {
        background-color: #eeffef;
        color: #00b51a;
        font-size: 55px;
    }

    .categorie-list-menu label {
        font-size: 60px;
    }

    /* Modal Support  */

    .help-img {

        width: 300px;
        height: 200px;
    }

    .vendindg-message {
        font-size: 30px;
    }

    .text-number {
        font-size: 80px;
    }


    .text-title-help {
        font-size: 45px;
        color: #071437;
    }

    .num-key-pad {
        font-size: 60px;
    }



    .vendindg-bg-stand {
        width: 100%;
    }

    .boxs-stands {
        height: 400px;
        width: 100%;
    }

    .stan {
        bottom: 9%;
        z-index: -1999;
    }

   

    .text-position-item {
        border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -webkit-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -moz-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -ms-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -o-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
    }

    .buttons-steps {
        position: absolute;
        z-index: 99;
        top: 50%;
    }


    .round-button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #0b5ed7;
        font-weight: 700;
        color: #fff;
        font-size: 49px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 1999;
        top: 12;
    }

    .background-image {
        width: 100%;
        bottom: -40;
        left: 0;
        z-index: 1;
        position: absolute;
    }

    .title-modal-categories,
    .title-modal-product {
        font-size: 35px;
        font-weight: 700;
    }

    .vending {
        width: 100%;
        margin-bottom: 250px;
    }

    .icon-button {
        font-size: 4rem;
    }

}


/* Mini Vending  */
@media (max-width: 767px) {
    .vending {
        width: 100%;
        margin-bottom: 60px;
    }

    /* Buttons Header */




    .round-button {
        border-radius: 50%;
        font-weight: 700;
        color: #fff;
        font-size: 70px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }

    .vending-flex-container {
        color: #000000;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
        background-color: #ffffff;
        height: 100%;
        width: 100%;
        gap: 1px;
    }

    .vending-img-product {
        width: 99%;
        height: 180px;
        object-fit: cover;
        /* border: 1px solid #ffffff; */

    }

    .vending-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        overflow: auto;
        flex-direction: row;
        position: absolute;
        z-index: 2;
    }

    .vending-menu-products {
        padding-bottom: 5rem;
        margin-top: 10px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        background-color: transparent;
    }

    .title-product-menu {
        font-size: 25px;
        font-weight: 800;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 220px;
    }

    .title-price-menu {
        font-size: 25px;
        color: #019f19;
        font-weight: 800;
        background-color: #00000000;

    }

    .text-position-item {
        border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -webkit-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -moz-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -ms-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
        -o-border-radius: 0.5rem 0.1rem 0.5rem 0.1rem;
    }


    /*Modal Product IMG   */


    .background-image {
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 1;
        position: absolute;
    }

    .foreground-image {
        z-index: 2;
        /* Ponerla adelante */
    }

    .buttons-steps {
        position: absolute;
        z-index: 99;
        top: 50%;
    }

    .round-button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #0b5ed7;
        font-weight: 700;
        color: #fff;
        font-size: 49px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 1999;
        top: 12;
    }

 

    .product-stand-stock {
        width: 100%;
        height: 842px;
        position: relative;
    }


    /* Buttons */


    /* Meth pays */
    .img-product-cart {
        width: 2rem;
        height: 2rem;
    }

    .img-pay-meth {
        width: 100px;
        height: 100px;
    }

    .img-pay-group:hover {
        background-color: #0025B8;
    }




    .modal-title-product {
        font-size: 2.5rem;
        font-weight: 700;
    }


    .modal-price-product {
        font-size: 35px;
        font-weight: 800;
        color: #00b51a;
        margin: 0rem 0;
    }

    .modal-price-product-before {
        color: red;
        font-size: 4rem;
        font-weight: 600;
        margin: 4rem 0rem 0rem 0rem;

    }

    .btn-success-send {
        background-color: #eeffef;
        color: #00b51a;
        font-size: 35px;
    }

    /* Modal Support  */
    .text-number {
        font-size: 40px;
    }

    .help-img {

        width: 120px;
        height: 100px;
    }

    .text-title-help {
        font-size: 20px;
        color: #071437;
    }

    .num-key-pad {
        font-size: 27px;
    }

    .btn-vending-danger {
        background-color: #ff0000;
        color: white;
        font-size: 30px;
    }



    .vendindg-bg-stand {
        width: 100%;

    }

    .boxs-stands {
        height: 300px;
        width: 100%;

    }

    .stan {
        display: none;
    }

    .vendindg-message {
        font-size: 18px;
    }

    .icon-button {
        font-size: 2rem;
    }

    .categorie-list-menu label {
        font-size: 30px;
    }

    .list-pays-vending {
        width: 400px;
    }




}