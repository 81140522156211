 /* Roots */

 /* End Roots */


 /*Vending Menu  */

 .vending-menu {
   width: 100%;
   height: 100vh;
   overflow-y: scroll;
   scroll-behavior: smooth;
   background-image: url('./assets/img/bg-vending.jpg');
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
 }

 .vending-input-qr {
   margin: -30rem;
 }

 .pre-account-menu {
   width: 100%;
   height: 100vh;
   overflow-y: scroll;
   scroll-behavior: smooth;
   /* background-image: url('./assets/img/pre-bg.svg'); */
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
 }

 .input-upc {
   position: fixed;
   top: -20rem;
 }

 .vending-top {
   background-color: var(--blanco);
   width: 640px;
   /* margin: 0 auto; */
   position: fixed;
   top: 0;
   z-index: 1030;
 }

 .vending-bottom {
   background-color: var(--blanco);
   border: 1px solid var(--blanco);
   border-radius: 10px;
   position: fixed;
   right: 5px;
   bottom: 0;
   z-index: 1030;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
 }

 .vending-item {
   /* background-image: url('./assets/img/bg-menu.svg'); */
   margin-top: 30px;
   height: 100%;
   /* position: absolute;
   z-index: 2; */
 }

 .imgEditModal {
   width: 200px;
   height: 200px;
   object-fit: cover;
   border-radius: 50%;
 }

 .imgitemsTable {
   width: 80px;
   height: 80px;
   object-fit: cover;
   border-radius: 50%;
 }

 .imgitemsList {
   width: 50px;
   height: 50px;
   object-fit: cover;
   border-radius: 50%;
 }








 /* Content Modal Product */
 /* .img-product-menu{
  width: 140px;
  height: 140px;

} */



 .card-body-product {
   background-color: transparent;
 }

 .vending-stand {
   margin-top: 0px;
 }

 .grid-row-vending {
   height: 70px;
   background-image: url('./assets/img//vending/stand.png');
 }

 /* lIST CATEGORIES  */
 .categorie-list-menu {
   margin: 20px;
   padding: 2px 0px;
   position: relative;
 }

 .categorie-list-menu::-webkit-scrollbar {
   width: 2px !important;
 }

 .categorie-list-menu ul {
   list-style: none;
   margin: 0;
   padding: 0;
 }

 .categorie-list-menu li {

   margin: 30px 0px;
 }





 .btn-outline-primary-vending {
   font-weight: 400;
   color: var(--primary-vending);
   background-color: var(--blanco);
   border: 1px solid var(--primary-vending);

 }





 /* Icons */
 .icon-button-setting-vending {
   font-size: 3rem;
 }

 .icon-button-params {
   font-size: 30px;
 }

 /* Empty Cart */
 .empty-cart {
   margin-top: 100px;
 }

 .empty-img {
   width: 80px;
   height: 80px;
 }

 .empty-title,
 .empty-msg {
   font-size: 25px;
 }


 @media (max-width: 767px) {


   .vending-menu {
     padding-bottom: 5rem;
     margin-top: 4rem;
     width: 100%;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }
 }

 @media (max-width: 820px) {

   .vending-menu {
     padding-bottom: 5rem;
     margin-top: 4rem;
     width: 100%;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }
 }


 @media (max-width: 1024px) {
   .vending-menu {
     padding-bottom: 5rem;
     margin-top: 4rem;
     width: 100%;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }
 }


 /* Desing Frame dimensions 1080x1920 Vendin Main  */
 @media screen and (min-width: 1080px) and (min-height: 1920px) {


   .vending-menu {
     width: 100%;
     height: 100%;
     overflow-y: scroll;
     scroll-behavior: smooth;
   }

   .vending-top {
     width: 100%;
     position: fixed;
     top: 0;
     right: 0;
     left: 0;
     z-index: 1030;
   }

   .vending-bottom {
     position: fixed;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1030;
   }

   .vending-item {
     margin-top: 30px;
     height: 100%;
     background-color: transparent;
     /* position: absolute;
     z-index: 2; */
   }

   .vending-row {
     display: flex;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-items: flex-start;
     align-content: flex-start;
     width: 100%;
     height: 100%;
     margin-top: 53px;
     overflow: auto;
     flex-direction: row;
     position: absolute;
     z-index: 2;
   }

   .vending-row div {
     margin: 0 auto;
     width: 255px;
     height: 390px;
     background-color: transparent;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     /* border: 1px solid var(--negro);     */
   }





   .title-price-menu {
     font-size: 2rem;
   }

   .vending-menu-stock {
     margin: 1rem 1rem 0;
     max-height: 100%;
     overflow-y: scroll;
     z-index: -99;
   }

   .vending-content {
     background-color: transparent;
   }





   .vendindg-img {
     margin: 0px 0 0 0;
   }

   .vending-qr {
     width: 80%;
     height: 100%;
   }

   .vending-button {
     margin-top: 0rem;
   }

   .button-sub-title {
     font-size: 3rem;
     margin: 0 0;
   }




   /* buttons  */


   .btn-vending,
   .btn-vending-succes,
   .btn-vending-secondary,
   .btn-vending-danger {
     font-size: 3rem;
     font-weight: 700;
     padding: 20px 40px;
     margin: 2rem 0;
     color: var(--blanco);

   }





   .btn-vending-secondary {
     background-color: #dcdcdc;
   }

   .btn-vending-danger {
     background-color: #AA0000;
   }

   /* KeyPad */
   /* keyPad Numeric  */
   .section-keyboard {
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     border-radius: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     -ms-border-radius: 30px;
     -o-border-radius: 30px;
     padding: 10px;
   }

   .keypad-input {
     color: var(--primary-vending);
     margin: 2.5rem 0;
     padding: 20px 0;
     font-size: 5.5rem;
     font-weight: 700;
     text-align: center;
     border: 2px solid rgb(226, 226, 226);
     letter-spacing: 20px;
   }

   .keyboard-main {
     background-color: transparent;
   }

   .keyboard-pad {
     margin: 0.5rem 0;
   }

   .keyboard-pad>.num,
   .delete {
     color: #000;
     font-weight: 800;
     text-align: center;
     font-size: 4rem;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
     padding: 45px 0px;
     width: calc(33.333% - 8px);
     margin: 0 4px 8px;
     cursor: pointer;
     transition: all 0.2s linear;
     -webkit-transition: all 0.2s linear;
     -moz-transition: all 0.2s linear;
     -ms-transition: all 0.2s linear;
     -o-transition: all 0.2s linear;
     border: 2px solid rgb(226, 226, 226);
     box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
     inset: 4px -4px 0px #d4c69d;
     inset: -4px 4px 0px #beb28d;
   }

   .key {
     width: 5rem;
     font-size: 3rem;
     margin: 5px;
   }

   .keySpaceDelete {
     font-size: 3rem;
     margin: 5px;
   }

   .keypadPrimary-input {
     color: var(--primary-vending);
     margin: 2.5rem 0;
     padding: 20px 0;
     font-size: 2.5rem;
     font-weight: 700;
     text-align: center;
     border: 2px solid rgb(226, 226, 226);
     letter-spacing: 5px;
   }


   .keyboard-pad>.num:hover {
     color: var(--primary-vending);
     background-color: #bae4dd37;
   }

   /* * keyboard */
   .keyboard-step {
     padding: 1rem;
     /* background-color: #bae4ddd9; */
   }

   .keyboard-title {
     text-align: center;
     font-weight: 700;
     font-size: 4rem;
   }



   .keyboard-steps-number {
     font-size: 3rem;
     font-weight: 600;
   }

   .keyboard-steps-number-active {
     color: var(--primary-vending);
     font-size: 3rem;
     font-weight: 600;
   }

   /* End KeyBoard */

   .menu-vending-title>h1 {
     font-size: 4.5rem;
     font-weight: 800;
   }


 



   .btn-other {
     font-size: 3rem;
     font-weight: 700;
     color: #1263d3;
     padding: 30px 70px;
   }

   .btn-other:hover {
     font-size: 3rem;
     font-weight: 800;
     color: #227ed3;
     background-color: #5fa3e64a;
     padding: 30px 70px;
   }


   .btn-left-border {
     border-radius: 60px 0 0 60px;
     -webkit-border-radius: 60px 0 0 60px;
     -moz-border-radius: 60px 0 0 60px;
     -ms-border-radius: 60px 0 0 60px;
     -o-border-radius: 60px 0 0 60px;
   }

   .btn-right-border {
     border-radius: 0 60px 60px 0;
   }



   .icon-button-params {
     font-size: 4rem;
   }



   .radio-group label {
     display: block;
     margin-bottom: 10px;
   }

   /* lIST CATEGORIES  */
   .categorie-list-menu {
     margin: 20px;
     padding: 2px 0px;
     position: relative;
   }

   .categorie-list-menu::-webkit-scrollbar {
     width: 2px !important;
   }

   .categorie-list-menu ul {
     list-style: none;
     margin: 0;
     padding: 0;
   }

   .categorie-list-menu li {

     margin: 30px 0px;
   }

  

   /* .button-container {
     display: inline-block;
     vertical-align: top;
   } */

   /* .button-container img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  } */
   /* 
   .button-container label {
     width: 100%;
      display: inline-block; 
     vertical-align: top;
     /* white-space: nowrap; 
     font-size: 3.5rem;
     padding: 1rem 0;
   } */

   .title-card-product {
     font-size: 14px;
   }

   .btn-close-categorie {
     font-size: 5rem;
     font-weight: 500;
   }



   .modal-product {
     font-size: 5rem;
     font-weight: 500;
   }





 

   .number-counter button:hover {
     background-color: #00725f;
     width: 11rem;
     height: 11rem;
   }

   .title-modal-cart {
     font-size: 5rem;

   }

   .title-modal-pay,
   .title-modal-stock {
     font-size: 3rem;
   }

   .title-modal-cart,
   .title-modal-pay,
   .title-modal-stock {
     font-weight: 800;
   }

   .title-small-pay {
     font-size: 2rem;
     font-weight: 700;
   }

   .text_cart_vending {
     font-size: 2rem;

   }

   .totals-labels>p {
     font-size: 3rem;
     font-weight: 700;
   }







   .title-product-shop-cart,
   .title-product-count,
   .title-product-price {
     font-size: 2.7rem;
   }

   .title-product-shop-cart {
     font-weight: 700;
   }

   .title-product-price {
     color: var(--primario);
     font-weight: 700;
   }

   .btn-vending-plack {
     font-size: 3rem;
     font-weight: 700;
     padding: 40px 35px;
     margin: 2rem 0;
   }

   .btn-count {
     color: var(--blanco);
     font-size: 3rem;
     font-weight: 700;
     padding: 5px 25px;
     margin: 2rem 0;
     border: 1px solid var(--primary-vending);
     background-color: var(--primary-vending);
   }

   /* Categories Menu  */

   .btn-check {
     background-color: var(--blanco);
   }

   .btn-outline-primary-venidng {
     font-weight: 400;
     color: var(--primary-vending);
     background-color: var(--blanco);
     border: 4px solid var(--primary-vending);
     border-radius: 20px;
     -webkit-border-radius: 20px;
     -moz-border-radius: 20px;
     -ms-border-radius: 20px;
     -o-border-radius: 20px;
   }



   .close-x {
     font-size: 2.5rem;
   }

   .label-wrap {
     overflow-wrap: break-word;
     word-wrap: break-word;
     word-break: break-word;
   }

   .placeholder {
     background-color: #f2f2f2;
     padding: 10px;
     border-radius: 4px;
   }

   .placeholder-line {
     height: 10px;
     background-color: #e0e0e0;
     margin-bottom: 5px;
   }

   .branding-mark {
     font-size: 1.5rem;
   }






  

  

   .title-count-stock {
     font-size: 3em;
   }

 

   .empty-cart {
     margin-top: 20rem;
   }

   .empty-img {
     width: 150px;
     height: 150px;
   }

   .empty-title,
   .empty-msg {
     font-size: 45px;
   }

   .met-pay {
     width: 100%;
     height: 500px;
     margin: 0px 0;
   }

   .bg-optimum-item {
     background-color: #1ca3234a;
   }

   .bg-warning-item {
     background-color: #f7e02f9b;
   }

   .bg-empty-item {
     background-color: #f72f2f47;
   }

   .menu-vending-temp>h3,
   .menu-vending-time>h3 {
     font-size: 3rem;
     font-weight: 800;
   }

   .round-button-count {
     width: 70px;
     height: 70px;
     border-radius: 50%;
     background-color: var(--primary-vending);
     border: none;
     color: white;
     font-size: 35px;
     font-weight: 600;
     position: relative;
     overflow: hidden;
   }

   .button-number {
     padding: 20px;
     font-size: 5.5rem;
   }

   .icon-trash {
     font-size: 3rem;
   }

   .name-product-cart {
     width: 370px;
   }

   .count-menu {
     bottom: 40px;
     left: 45px;
   }

   .stock-container {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-start;
     align-items: flex-start;
     align-content: flex-start;
     width: 100%;
     background-color: transparent;
     /* height: 80vh; */
     overflow: auto;
     flex-direction: row-reverse;
   }

   .stock-container div {
     width: 90px;
     height: 280px;
   }

   .name-product-cart-end {
     font-size: 3rem;

   }

   .met-paymens-buttons {
     margin-top: 260px;
   }

   .section-thanks {
     background-image: url('./layouts//Vending/img/backpad.png');
     background-size: cover;
     position: relative;
   }

   .mensaje {
     width: 100%;
     color: #000;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     background-color: rgba(255, 255, 255, 0.8);
     /*Colordefondodelmensajecontransparencia*/
     padding: 10px 20px;
     font-size: 24px;
     font-weight: bold;
   }



   .buttons-steps {
     position: absolute;
     z-index: 99;
     top: 50%;
   }

 }

 .input-wrapper {
   position: relative;
   width: 271px;
 }

 .input {
   box-sizing: border-box;
   color: #191919;
   padding: 15px 15px 15px 35px;
   width: 100%;
 }

 .input.password {
   padding: 15px 35px 15px 15px;
 }

 .input-icon {
   color: #191919;
   position: absolute;
   width: 20px;
   height: 20px;
   left: 12px;
   top: 50%;
   transform: translateY(-50%);
 }

 .input-icon.password {
   left: unset;
   right: 12px;
 }

 .transition-3 {
   transition: all 0.33s ease;
   --webkit-transition: all 0.33s ease;
   -moz-transition: all 0.33s ease;
 }

 .transition-5 {
   transition: all 0.5s ease;
   --webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
 }



 /* ! Revisar css que afecta los botones  */
 /* .btn-check+.btn:hover {
   font-weight: 700;
   color: var(--blanco);
   background-color: var(--primary-vending);
   border-color: var(--primary-vending);
 }

 .btn-check:checked+.btn,
 .btn.active,
 .btn.show,
 .btn:first-child:active,
 :not(.btn-check)+.btn:active {
   font-weight: 700;
   border: 1px solid var(--primary-vending);
   color: var(--blanco);
   background-color: var(--primary-vending);
 }
 
   .btn-check+.btn:hover {
     font-weight: 700;
     color: var(--blanco);
     background-color: var(--primary-vending);
     border-color: var(--primary-vending);
   }

   .btn-check:checked+.btn,
   .btn.active,
   .btn.show,
   .btn:first-child:active,
   :not(.btn-check)+.btn:active {
     font-weight: 700;
     border: 1px solid var(--primary-vending);
     color: var(--blanco);
     background-color: var(--primary-vending);
   }

   .btn-check+.btn:hover {
  font-weight: 700;
  color: var(--primario);
  background-color: var(--primario-light);
  border-color: var(--primario);
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  font-weight: 700;
  border: 1px solid var(--primario);
  color: var(--primario);
  background-color: var(--primario-light);
  border-color: var(--primario);
}
 
 
 */